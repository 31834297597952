<template>
  <div class="NavbarWrapper">
    <NavbarDesktop
      :userIsAnonymous="userIsAnonymous"
      :hideNavbarLeftLogo="hideNavbarLeftLogo"
      :replaceNameOnCoins="replaceNameOnCoins"
      :coinsValue="coinsValue"
      :hideProfileButton="hideProfileButton"
    />
  </div>
</template>

<style scoped>
.NavbarWrapper {
  position: relative;

  width: 100%;
  height: fit-content;

  display: flex;
}
</style>

<script>
import NavbarDesktop from "@/components/Navbar/NavbarDesktop.vue";
export default {
  props: {
    userIsAnonymous: {
      type: Boolean,
      default: () => {
        return false;
      },
    },

    hideNavbarLeftLogo: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    replaceNameOnCoins: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    coinsValue: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    hideProfileButton: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  components: { NavbarDesktop },
  name: "NavbarCombined",
  data() {
    return {};
  },
  computed: {},
  created() {},
};
</script>
