<template>
  <div class="NavbarDesktop noselect">
    <router-link class="LeftSide" to="/">
      <template v-if="!hideNavbarLeftLogo">
        <div class="ForusImage"></div>
        <span>Октагон</span>
      </template>
    </router-link>
    <div class="RightSide" v-if="!userIsAnonymous">
      <div class="TasksLiked" v-if="!replaceNameOnCoins">
        <div class="WhiteHeart"></div>
        <span>{{ getJobFollowCounter }}</span>
      </div>
      <div class="Menu" @click="openDropDown = !openDropDown">
        <div class="Left">
          <template v-if="replaceNameOnCoins">
            <Octocoins class="OctaCoinsStyle" :BorderColor="'transparent'">
              <template v-slot:content>
                <span class="Product-Price-Text"
                  >{{ coinsValue }} {{ _getOctaCoinsText }}</span
                >
              </template>
            </Octocoins>
          </template>
          <span v-else class="UserName">{{ getUserName }}</span>
        </div>
        <div class="Right">
          <div class="AvatarPlaceholder"></div>
          <div class="NavbarDropdown"></div>
        </div>

        <div class="modal" v-if="openDropDown">
          <div class="modal-content">
            <div class="modal-block">
              <router-link
                v-if="!hideProfileButton"
                to="/beta/profile"
                @click="openDropDown = false"
                >Профиль
              </router-link>
              <router-link
                to="/"
                @click="
                  logout();
                  openDropDown = false;
                "
                >Выйти
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.OctaCoinsStyle {
  gap: 2px;
  padding: 0px;
}
.OctaCoinsStyle:deep(.coinsLogo) {
  width: auto;
  height: 15.85px;

  aspect-ratio: 0.4705 / 1;
}

.Product-Price-Text {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 13px;
  font-weight: 500;
  line-height: 15.85px;
  text-align: left;
  color: #ffffff;
}

.NavbarDesktop {
  position: relative;

  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 40px;
}

.NavbarDesktop > * {
  margin: auto 0%;
}

.LeftSide,
.RightSide {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  width: fit-content;
  height: fit-content;
}
.LeftSide > *,
.RightSide > * {
  margin-top: auto;
  margin-bottom: auto;
}

.ForusImage {
  position: relative;
  display: block;

  width: 49px;
  height: 44px;

  background: url("./../../assets/img/Landing/LogoForNavbar.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.WhiteHeart {
  position: relative;
  display: block;

  width: 19px;
  height: 17px;

  background: url("./../../assets/img/Landing/HeartWhite.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.AvatarPlaceholder {
  position: relative;
  display: block;

  width: 20px;
  height: 20px;

  background: url("./../../assets/img/Landing/NavbarAvatarPlaceholder.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.NavbarDropdown {
  position: relative;
  display: block;

  width: 8px;
  height: 4px;

  background: url("./../../assets/img/Landing/NavbarDropdown.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.LeftSide {
  gap: 10px;
}
.RightSide {
  gap: 50px;
}
.LeftSide > span {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #fff;
  font-family: "Montserrat";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 22.4px */
  letter-spacing: 0.048px;
}

.TasksLiked {
  position: relative;

  width: fit-content;
  height: fit-content;

  padding: 3px 10px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5px;

  border-radius: 20px;
  background: #413e74;
}
.TasksLiked > *,
.Menu > *,
.Menu > .Right > * {
  margin-top: auto;
  margin-bottom: auto;
}
.TasksLiked > span {
  position: relative;

  width: fit-content;
  height: fit-content;

  color: #fff;

  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%; /* 23.25px */
}
.Menu {
  position: relative;

  width: fit-content;
  height: fit-content;

  padding: 5px 10px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;

  border-radius: 20px;
  background: linear-gradient(91deg, #1e768e 2.08%, #9652d4 98.48%);

  cursor: pointer;
}
.Menu > .Right {
  position: relative;

  width: fit-content;
  height: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 4px;
}
.Menu > .Left {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.Menu > .Left > .UserName {
  color: #fff;

  /* tag */
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

a {
  text-align: left;
  font-size: 15px !important;
  font-family: "Montserrat";
  color: #c8c3de !important;
}

a:hover {
  color: #fff !important;
}

.modal {
  z-index: 1;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  top: 40px;

  width: 100%;
  height: fit-content;
}

.modal-block {
  display: flex;
  flex-direction: column;
  height: fit-content;
  margin: 0;
}

.modal-block span {
  /* Body Small */
  color: #ffffff;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.1875rem; /* 135.714% */
  letter-spacing: -0.0175rem;
}

.modal-content {
  z-index: 2;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  height: fit-content;
  width: fit-content;

  padding: 0.8rem 1.5rem;
  gap: 1.875rem;

  border-radius: 20px;
  background-color: #292558;

  box-shadow: 0px 13px 24px 0px rgba(35, 35, 35, 0.137);
}

.modal-title {
  color: #232323;

  /* H3 */
  font-family: Inter;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.875rem; /* 125% */
  letter-spacing: -0.03rem;
}

.modal-block span {
  cursor: pointer;
}

@media (max-width: 700px) {
  .NavbarDesktop > .RightSide > .Menu > .Left {
    display: none;
  }
}
</style>

<style scoped>
@media (max-width: 700px) {
  .modal {
    left: -120%;
    width: fit-content; /* здесь меняем с 100% на fit-content */
  }

  a {
    font-family: "Montserrat";
    color: #c8c3de !important;
  }

  a:hover {
    color: #fff !important;
  }
}
@media (max-width: 480px) {
  .modal {
    left: -120%;
    width: fit-content; /* здесь меняем с 100% на fit-content */
  }

  .RightSide {
    gap: 20px;
  }
  a {
    font-family: "Montserrat";
    color: #c8c3de !important;
  }

  a:hover {
    color: #fff !important;
  }
}
</style>

<script>
import Octocoins from "@/components/CustomElements/Octocoins.vue";

import { getCoinsText } from "@/middlewares/getCoinsText.js";

import { logout } from "@/api/auth.js";
import { mapGetters } from "vuex";

export default {
  props: {
    userIsAnonymous: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    hideNavbarLeftLogo: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    replaceNameOnCoins: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    coinsValue: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    hideProfileButton: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  components: { Octocoins },
  name: "NavbarDesktop",
  data() {
    return {
      openDropDown: false,
    };
  },
  watch: {
    user: {
      handler(val) {
        if (this.id) {
          this.id = this.user.id;
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/isLoggedIn",
    }),
    getUserName() {
      return `${this?.user?.surname} ${this?.user?.name}`;
    },
    getJobFollowCounter() {
      return `${this?.user?.Jobs.length}`;
    },

    _getOctaCoinsText() {
      return getCoinsText(this.coinsValue);
    },
  },
  methods: {
    async logout() {
      try {
        logout();
        this.$store.dispatch("auth/logout");
      } catch (error) {}
    },
  },
};
</script>
